var brandColor1 = "#0900BD";
var brandColor2 = "#FFC800";
var brandTextColor = "#ffffff";

var personalizedColors = {
   darkBlueBackground: brandColor1,
   whiteText: "#FFFFFF",
   entryPointBackground: brandColor2,
   lighterBackground: "#ffffff",
   primaryButtonBackground: brandColor2,
   primaryButtonColor: "#000000",
   secondaryButtonBackground: brandColor1,
   secondaryButtonColor: "#FFFFFF",
   lightGreyBackground: "#eeeeee",
   lightGreyForeground: "#333333",
};

var brandedColors = {
    Chat: {
        MessageListItem: {
            FromOthers: {
                Bubble: {
                    background: personalizedColors.lightGreyBackground,
                    color: personalizedColors.lightGreyForeground,
                },
                Avatar: {
                    background: personalizedColors.lightGreyBackground,
                    color: personalizedColors.lightGreyForeground
                },
                Header: {
                    color: personalizedColors.lightGreyForeground,
                },
            },
            FromMe: {
                Bubble: {
                    background: brandColor1,
                    color: brandTextColor,
                },
                Avatar: {
                    background: brandColor1,
                    color: brandTextColor,
                },
                Header: {
                    color: brandTextColor
                },
            },
        },
        MessageInput: {
            Button: {
                background: brandColor1,
                color: brandTextColor
            }
        },
        MessageCanvasTray: {
            Container: {
                background: personalizedColors.darkBlueBackground,
                color: personalizedColors.whiteText
            }
        },
    },

    MainHeader: {
        Container: {
            background: "#ffffff",
            color: brandColor1,
        },
        Logo: {
            fill: brandTextColor
        }
    },

    EntryPoint: {
        Container: {
            background: personalizedColors.entryPointBackground,
            color: "#000000",
            "&:hover": {
                background: "#ffd84c"
            }
        }
    },

    PreEngagementCanvas: {
        Container: {
            background: personalizedColors.lighterBackground
        },

        Form: {
            SubmitButton: {
                background: personalizedColors.primaryButtonBackground,
                color: personalizedColors.whiteText
            }
        }
    }
};

const appConfig = (strings, language) => ({
    disableLocalStorage: process.env.REACT_APP_DISABLE_LOCAL_STORAGE === "true" ? true : false, // DEBUG
    accountSid: process.env.REACT_APP_ACCOUNT_SID,
    flexFlowSid: process.env.REACT_APP_FLEX_FLOW_SID,
    colorTheme: {
        overrides: brandedColors
    },
    startEngagementOnInit: false,
    preEngagementConfig: {
        description: strings.Welcome,
        fields: [
            {
                label: strings.WhatIsYourName,
                type: "InputItem",
                attributes: {
                    name: "friendlyName",
                    type: "text",
                    required: true
                },
            },
            // {
            //     label: "Qu'est-ce que c'est votre numero BP?",
            //     type: "InputItem",
            //     attributes: {
            //         name: "bp",
            //         type: "text",
            //         required: false
            //     },
            // },
            {
                label: strings.Language,
                type: "SelectItem",
                attributes: {
                    name: "language",
                    required: true,
                    readOnly: false
                },
                options: [
                    {
                        value: "nl",
                        label: "Nederlands",
                        selected: language === "nl"
                    },
                    {
                        value: "fr",
                        label: "Français",
                        selected: language === "fr"
                    }
                ]
            },

        ],
        submitLabel: strings.Start,
    },
});

export default appConfig;
