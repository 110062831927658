import styled from '@emotion/styled';

export const AppStyles = styled('div')`
.Twilio-CommonStyles, .Twilio-RootContainer {
    font-family: Convergence, Roboto, "Open Sans", sans-serif;
}

.Twilio.Twilio-MainContainer, .Twilio.Twilio-EntryPoint {
    z-index: 1002;
}

p {
    font-size: 1em !important;
}

textarea {
    min-height: 0 !important;
    height: auto !important;
}

.Twilio-ChatItem-Avatar > img {
    display: block;
    margin-top: -6px;
}

@media (max-height: 600px) {
    .Twilio.Twilio-MainContainer {
        bottom: 0;
        height: 100%;
    }

    .Twilio.Twilio-MainContainer + .Twilio.Twilio-EntryPoint {
        display: none;
    }
}

@media (max-width: 400px) {
    .Twilio.Twilio-MainContainer {
        right: 0;
    }
}

strong {
    font-weight: bold;
}

input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    border-radius: 0;
    padding-left: 6px;
    padding-top: 7px;
    padding-right: 6px;
    padding-bottom: 7px;
    margin: 0;
    background: none;
    width: 100%;
    height: auto;
    font-family: inherit;
    background: none;
    border: 1px solid #C6CAD7;
    min-height: none;
    min-width: none;
    font-size: 12px;
}

input[type="text"]:focus {
    border: 1px solid #197bdd;
}

textarea {
    padding: 8px 12px 8px 12px;
    color: rgb(34, 34, 34);
}

label {
    margin-top: 0;
}
`;
