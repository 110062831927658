import styled from '@emotion/styled';

export const BubbleStyles = styled('div')`
.body > p:first-of-type {
  margin-top: 0;
}

.body > p:last-of-type {
  margin-bottom: 0;
}

.message-header {
  display: flex;
  flex-direction: colum;
  justify-content: space-between;

  font-size: .9em;
}

.message-header .author {
  margin-right: 1em;
}
`;
