// Comment out following polyfills if you don't need IE11 support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import './index.css';
import App from './App';

const root = document.getElementById("js-bfwebchat");
const rootLanguage = root.getAttribute("data-lang");
const htmlLanguage = document.documentElement.getAttribute("lang");

const language = rootLanguage || htmlLanguage || "fr";

ReactDOM.render(
    <App language={language} />,
    document.getElementById("js-bfwebchat")
);
