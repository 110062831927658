import { combineReducers } from 'redux';
import { WebchatReducer } from '@twilio/flex-webchat-ui';

const rootReducer = (() => {
    const reducers = { flex: WebchatReducer };
    return {
        addReducer: function (slice, reducer) {
            reducers[slice] = reducer;
        },
        stringReducer: function (slice, reducer) {
            const currentReducer = reducers[slice];
            reducers[slice] = (state, action) => reducer(currentReducer(state, action), action);
        },
        combinedReducers: function () {
            return combineReducers(reducers);
        }
    };
})();

export default rootReducer;
