import React from 'react';
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import * as axios from 'axios';

import Bubble from './Bubble.jsx';
import { AppStyles } from './App.Styles.js';

import ExtendedFlexState from './ExtendedFlexState.js';
import CustomReducer from './CustomReducer.js';

import config from './app-config.js';
import locale from './locale.js';

const PUBLIC_URL = process.env.PUBLIC_URL || "";

class App extends React.Component {

    state = {};

    constructor(props) {
        super(props);

        const lang = props.language || "fr";

        FlexWebChat.MainContainer.defaultProps.width = "400px";
        FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;
        FlexWebChat.MessagingCanvas.defaultProps.avatarCallback = () => `${PUBLIC_URL}/img/fixs.svg`;
        FlexWebChat.MainHeader.defaultProps.imageUrl = `${PUBLIC_URL}/img/fixs.svg`;
        FlexWebChat.MainHeader.defaultProps.titleText = locale[lang].WelcomeMessage;

        FlexWebChat.MessageBubble.Content.replace(<Bubble key="body" />);

        FlexWebChat.Manager.create(config(locale[lang], lang))
            .then(manager => {
                ExtendedFlexState.stringReducer('flex', CustomReducer(manager));
                manager.store.replaceReducer(ExtendedFlexState.combinedReducers());

                Object.keys(locale[lang]).forEach(key => {
                    manager.strings[key] = locale[lang][key];
                });

                FlexWebChat.Actions.on("afterStartEngagement", (payload) => {
                    const { channelSid } = manager.store.getState().flex.session;

                    const attributes = {}
                    try {
                        attributes.friendlyName = payload.formData.friendlyName;
                        attributes.url = window.location.href;
                    } catch (e) {
                        console.err("Tried to set attributes but failed. Will do without them.", e);
                    }

                    axios.post(`https://${process.env.REACT_APP_DOMAIN}/fn/programmableChat`, {
                        ChannelSid: channelSid,
                        EventType: "onChannelAdded",
                        Body: "",
                        From: "",
                        Attributes: JSON.stringify(attributes)
                    });
                });

                this.setState({ manager });
            })
            .catch(error => this.setState({ error }));
    }

    render() {
        const { manager, error } = this.state;

        if (manager) {
            return (
                <AppStyles>
                  <FlexWebChat.ContextProvider manager={manager}>
                    <FlexWebChat.RootContainer />
                  </FlexWebChat.ContextProvider>
                </AppStyles>
            );
        }

        if (error) {
            console.error("Failed to initialize Flex Web Chat", error);
        }

        return null;
    }
}

export default App;
