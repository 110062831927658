export default {
    nl: {
        WelcomeMessage: "Welkom bij Brussel Fiscaliteit",
        EntryPointTagline: "Praat met ons",
        TypingIndicator: "{0} is aan het typen",
        Read: "Gelezen",
        Today: "VANDAAG",
        Yesterday: "GISTEREN",
        InputPlaceHolder: "Typ uw bericht",
        FieldValidationRequiredField: "Dit veld is verplicht",
        SendMessageTooltip: "Stuur bericht",
        MessageCanvasTrayContent: `
            <h6>Bedankt om met ons te chatten!</h6>
            <p>Aarzel niet om ons opnieuw te contacteren als u nog vragen zou hebben.</p>`,

        Welcome: "Welkom!",
        Language: "Taal",
        WhatIsYourName: "Wat is uw naam?",
        Start: "Start",
    },
    fr: {
        WelcomeMessage: "Bienvenue chez Bruxelles Fiscalité",
        EntryPointTagline: "Parlez avec nous",
        TypingIndicator: "{0} écrit",
        Read: "Lu",
        Today: "AUJOURD'HUI",
        Yesterday: "HIER",
        InputPlaceHolder: "Tapez votre message",
        FieldValidationRequiredField: "Champs obligatoire",
        SendMessageTooltip: "Envoyer",
        MessageCanvasTrayContent: `
            <h6>Ce fut un plaisir de discuter avec vous!</h6>
            <p>Si vous avez encore des questions, n'hésitez pas à nous contacter à nouveau.</p>`,

        Welcome: "Bienvenue!",
        Language: "Language",
        WhatIsYourName: "Quel est votre nom?",
        Start: "Commencer",
    },
    en: {
        WelcomeMessage: "Welcome to Brussels Fiscality",
        EntryPointTagline: "Talk with us",
        TypingIndicator: "{0} is typing",
        Read: "Read",
        Today: "TODAY",
        Yesterday: "YESTERDAY",
        InputPlaceHolder: "Type your message",
        FieldValidationRequiredField: "This field is required",
        SendMessageTooltip: "Send Message",
        MessageCanvasTrayContent: `
            <h6>Thanks for chatting with us!</h6>
            <p>If you have any more questions please reach out to us again.</p>`,

        Welcome: "Welcome!",
        Language: "Language",
        WhatIsYourName: "What is your name?",
        Start: "Start",
    }
};
