import React from 'react';

import * as ReactMarkdown from 'react-markdown';
import * as moment from 'moment';

import { BubbleStyles } from './Bubble.Styles.js';

class Bubble extends React.Component {

    render () {
        const { message, member } = this.props;

        return (
            <BubbleStyles>
              <div className="message-header">
                <strong className="author">{member.friendlyName}</strong>
                <span className="time">{moment(message.source.state.timestamp).format('H:mm')}</span>
              </div>
                <ReactMarkdown className="body" source={message.source.state.body} linkTarget="_blank" />
            </BubbleStyles>
        );
    }

}

export default Bubble;
