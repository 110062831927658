const initialState = {
    value: false
};

const CustomReducer = manager => (state = initialState, action) => {
    switch (action.type) {
    case 'CHANNEL_ADD_MESSAGE':
        if (action.payload.state.attributes.action === "end") {
            return {
                ...state,
                session: {
                    ...state.session,
                    engagementStage: "CF_PRE_ENGAGEMENT",
                    tokenPayload: undefined,
                },
                chat: {
                    ...state.chat,
                    channels: {},
                    users: {},
                    channelInput: {},
                    messageList: {
                        ...state.chat.messageList,
                        activeChatChannel: undefined,
                    }
                }
            };
        }
        return state;
    case 'CF_ACTION_TOGGLE_ENTRY_POINT':
        if (!state.session.isEntryPointExpanded) {
            return {
                ...state,
                session: {
                    ...state.session,
                    engagementStage: "CF_PRE_ENGAGEMENT",
                    tokenPayload: undefined,
                },
                chat: {
                    ...state.chat,
                    channels: {},
                    users: {},
                    channelInput: {},
                    messageList: {
                        ...state.chat.messageList,
                        activeChatChannel: undefined,
                    }
                }
            };
        }
        return state;
    default:
        return state;
    }
};

export default CustomReducer;
